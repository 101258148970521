@use 'mixins';
@use 'designSystem';
@use 'theme';
@use 'responsive';
@use 'const';

.page {
  @include mixins.standardPage;

  .heroBuilder {
    width: 100%;

    &.financeHeroBuilder {
      @include theme.apply(background, neutral-white);

      div[class*='fullwidth'] > div {
        padding-top: 64px;
        padding-bottom: 64px;

        @include responsive.mobile {
          padding-top: 48px;
          padding-bottom: 48px;
        }
      }
    }
  }

  .inner {
    @include mixins.standardPageInner;

    .privacy {
      min-height: 100vh;
      display: flex;
      gap: 80px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & > div {
        width: 100%;
        max-width: const.$contentMaxWidth;

        & > h1 {
          text-align: center;

          @include designSystem.textStyleHeadingTitle54;

          @include responsive.ltMobile {
            @include designSystem.textStyleHeadingLarge42;

            text-align: left;
          }

          margin-bottom: 32px;
        }

        & > h2 {
          @include designSystem.textStyleBodyLarge24;

          font-weight: bold;
          margin-bottom: 24px;

          @include responsive.ltMobile {
            @include theme.apply(color, foreground-default);
          }
        }

        & > p {
          @include designSystem.textStyleBodyLarge24;
          @include theme.apply(color, foreground-default);
        }

        & > p:not(:last-of-type) {
          margin-bottom: 32px;
        }

        & > ul li {
          @include designSystem.textStyleBodyLarge24;
          @include theme.apply(color, foreground-default);
        }

        /** below styles were added from what figma design looks like
            note the h1 and first of type work in this case due to
            the privacy page always having the intial text the same */
        & > p:first-of-type {
          @include designSystem.textStyleBodyLarge30;
          @include theme.apply(color, foreground-muted);

          text-align: center;
          margin: 0 0 80px;

          @include responsive.ltMobile {
            margin: 0 0 96px;
            text-align: left;
          }
        }

        & > p > a {
          @include theme.apply(color, action-default);

          text-decoration: none;

          @include responsive.ltMobile {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
